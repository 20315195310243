<template>
  <main class="home">
    <div class="page-header py-5">
      <div class="container-fluid">
        <breadcrumbs></breadcrumbs>
        <div class="row">
          <div class="col-sm-9">
            <h1 class="page-title">
              {{ title }}<br />
              <small>
                <router-link to="download">{{ release }}</router-link>
              </small>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <h2 class="mt-5">Components</h2>
      <component-list></component-list>
    </div>

    <div class="main--home"></div>
  </main>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      title: "myLTSA Styleguide",
      release: "v1.1.14",
    };
  },
};
</script>
